import Axios  from 'axios';
import { useNavigate } from 'react-router-dom';
import { userData } from '../core/helper';

const getHeaders = headers => {
    const objectHeaders = {
        'Accept-Language': 'es-MX',
        'Content-Type': 'application/json'
    }
    if( headers?.contentType ) {
        objectHeaders['Content-type'] = headers.contentType;
    }
    if( headers?.token ) {
        objectHeaders.token = headers.token
    }
    if( headers?.refreshtoken ) {
        objectHeaders.refreshtoken = headers.refreshtoken
    }
    if( headers?.authorization ) {
        objectHeaders['Authorization'] = headers.authorization
    }
    const userDataInfo = userData();
    if( userDataInfo && userDataInfo.token ) {
        objectHeaders['X-Authorization'] = `Bearer ${userDataInfo.token}`
    }
    return objectHeaders;
    }

export const appApi = (url, headers) => {
    
    const axiosInstance = Axios.create({
        baseURL: url,
        headers: getHeaders(headers)
    })

    axiosInstance.interceptors.response.use(response => response, error => {
        if (error) {
            if (error.response?.status === 401) {
                const navigate = useNavigate();
                navigate("/login")
            }
            return Promise.reject(error);
        }
    });

    return axiosInstance;
}