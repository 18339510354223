import { TalentList } from "./components/list/TalentList"
import { Header } from "../../components/header/Header";
import styles from './talentInfo.module.scss'
import modalStyles from '../../components/modal/modal_base.module.scss';
import notificationStyles from '../../components/notification/notification.module.scss';
import { createContext, useEffect, useState } from "react";
import { TalentResume } from "./components/resume/TalentResume";
import { TalentNewEvaluation } from "./components/evaluation/new/TalentNewEvaluation";
import ModalBase from "../../components/modal/ModalBase";
import admirationIcon from "../../../assets/icons/admiration_icon.svg";
import confirmationIcon from "../../../assets/icons/confirm_icon.svg";
import Notification from "../../components/notification/Notification";
import { getCatalog } from "../../api/talentInfoClient";

export const AppContext = createContext();


export const TalentMain = () => {

    const [steper, setSteper] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [filter, setFilter] = useState('');
    const [talentId, setTalentId] = useState();
    const [talent, setTalent] = useState({});
    const [review, setReview] = useState();
    const [checkup, setCheckup] = useState();
    const [tab, setTab] = useState(1);
    const [modalFlag, setModalFlag] = useState(false);
    const [modalContent, setModalContent] = useState(undefined);
    const [skillsSuggestions, setSkillsSuggestions] = useState([]);
    const [catLevelSatisfaction, setCatLevelSatisfaction] = useState();

    const loadCatLevelSatisfaction = () => {
        getCatalog(1000030057, 0, 200, '&sort=id&direction=ASC').then(({status, data}) => {
            if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) &&  data.result.content.length > 0) {
                let catTmp = [];
                data.result.content.map( l => {
                    const type = { id: l.catalogItemId, optionName: l.valueItem, description: l.description }
                    catTmp.push(type);
                })
                setCatLevelSatisfaction(catTmp);
            }
        }).catch( error => {
            console.log(error);
        })
    }

    useEffect(() => {
        loadCatLevelSatisfaction();
    }, [])

    const [notificationData, setNotificationData] = useState({
        notificationStyle: notificationStyles.notification_wrapper,
        closeIcon: true
    });

    const notification = <Notification data={ notificationData } />;

    const displayNotification = (message, isWarn, topPosition, withCloseIcon, timer) => {
        const style = isWarn ? notificationStyles.notification_wrapper_warning : notificationStyles.notification_wrapper;
        const icon = isWarn ? admirationIcon : confirmationIcon;
        const notificationData = {
            notificationStyle: style,
            message: message,
            icon: icon,
            top: topPosition,
            closeIcon: withCloseIcon
        }
        setNotificationData({
            ...notificationData,
            notificationStyle: ( `${ style } ${notificationStyles.active}` )
        });
        if( timer ) {
            setTimeout(() => {
                setNotificationData(notificationData)
            }, timer);
        }
    }

    return (
        <AppContext.Provider value={{
            steper,
            setSteper,
            currentPage, 
            setCurrentPage,
            filter,
            setFilter,
            talentId, 
            setTalentId,
            talent,
            setTalent,
            review,
            setReview,
            checkup,
            setCheckup,
            catLevelSatisfaction,
            tab,
            setTab,
            setModalFlag,
            modalContent,
            setModalContent,
            displayNotification,
            setNotificationData,
            skillsSuggestions,
            setSkillsSuggestions
        }}>
            <div className={ `${ modalStyles.modal_wrapper } ${ modalFlag ? modalStyles.modal_active : '' }` }>
                { modalFlag && <ModalBase /> }
                <div className={ styles.header_fixed }>
                    <Header />
                </div>
                <div className={ styles.container } style={ { marginTop: steper === 2 ? '140px' : '100px' } }>
                    { notification }
                    { steper === 0 && <TalentList /> }
                    { steper === 1 && <TalentResume /> }
                    { steper === 2 && <TalentNewEvaluation/> }
                </div>
            </div>
        </AppContext.Provider>
    )
}
