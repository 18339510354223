import { appApi } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;
const appName = process.env.REACT_APP_NAME;

export const saveLog = request => appApi(apiUrl).post('error-logs',{
    ...request,
    applicationName: appName,
    moduleName: appName,
    severity: "HIGH",
    status: "OPEN"
});