import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../TalentMain";
import styles from '../../talentInfo.module.scss';
import { FEEDBACK_DESC, MONTHS, yearsHardCode, yearsOrder } from "../../../../core/hardcode";
import { defaultStringEmpty, serializeSelectValue, unsearializaDateString, userData } from "../../../../core/helper";
import arrowView from '../../../../../assets/icons/table_detail_arrow.svg';
import ReviewsNotFound from '../../../../../assets/img/reviews_not_found.png';
import { getFeedbacks } from "../../../../api/talentInfoClient";
import { Pagination } from "../../../../components/pagination/Pagination";
import { Loading } from "../../../../components/loading/Loading";
import { FeedbackArrows } from "./FeedbackArrows";
import { FeedbackStarts } from "./FeedbackStarts";

export const getStatusDescription = (id, catFeedbackStatus) => {
    let statusDescription = '';
    const feedbackStatus = catFeedbackStatus.find( c => c.id === id);
    if( feedbackStatus ) {
        statusDescription = feedbackStatus.name;
    }
    return statusDescription;
}

export const Evaluations = ({
    catFeedbackStatus
}) => {
    const appContext = useContext(AppContext);

    const pageSize = 5;
    const [reviews, setReviews] = useState([]);
    const [reviewsPagged, setReviewsPagged] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const setSelectDates = review => {
        const reviewTmp = { ...review };
        const yearHardCode = yearsHardCode(yearsOrder.desc);
        const reviewDate = unsearializaDateString(reviewTmp.date);
        const dayLength = reviewDate.day.length;
        const reviewDay = dayLength === 2 ? reviewDate.day : ( dayLength > 2 ? reviewDate.day.substring(0, 2) : reviewDate.day );
        const reviewMes = MONTHS.find( m => m.id === reviewDate.monthId );
        const reviewAnio = yearHardCode.find( y => y.optionName === reviewDate.yearDesc);
        reviewTmp.reviewDay = reviewDay;
        reviewTmp.reviewMes = serializeSelectValue(reviewMes);
        reviewTmp.reviewAnio = serializeSelectValue(reviewAnio);
        return reviewTmp;
    }

    const sorterDate = (date1, date2) => {
        let result = 0;
        if (date1 < date2) {
            result = 1
        } else if (date1 > date2) {
            result = -1
        }
        return result;
    }

    const fetchFeedbacks = () => {
        appContext.setModalContent( <Loading /> );
        appContext.setModalFlag(true);
        const userDataInfo = userData();
        getFeedbacks(userDataInfo.userId, appContext.talent.id).then( response => {
            if( response.status === 200 && response.data && response.data.code === 200 && response.data.result && Array.isArray(response.data.result) ) {
                let reviewsTmp = [];
                response.data.result.map( review => {
                    const reWithDates = setSelectDates(review);
                    reWithDates.period = `${reWithDates.reviewDay} de ${reWithDates.reviewMes.description} ${reWithDates.reviewAnio.description}`;
                    reviewsTmp.push(reWithDates);
                });
                reviewsTmp = reviewsTmp.sort((a, b) => sorterDate(a.date, b.date));
                setReviews(reviewsTmp);
                setTotalPages(Math.ceil(reviewsTmp.length/pageSize));
                setReviewsPagged(reviewsTmp.slice(0, pageSize));
                appContext.setModalFlag(false);

                // Check if userDataInfo get talentId, feedbackId and checkupId
                if( userDataInfo && userDataInfo.talentId && userDataInfo.feedbackId && userDataInfo.checkupId ) {
                    onClickDetail(reviewsTmp.find(f => f.id === userDataInfo.feedbackId));
                    appContext.setTab(6);
                    delete userDataInfo.talentId;
                    delete userDataInfo.feedbackId;
                    delete userDataInfo.checkupId;
                    localStorage.setItem('userInfo', JSON.stringify(userDataInfo));
                }
            } else {
                appContext.setModalFlag(false);
            }
        }).catch(error => {
            console.log(error);
            appContext.setModalFlag(false);
        })
    }

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const onClickDetail = review => {
        appContext.setReview(review);
        appContext.setTab(5);
    }

    const onClickBackPage = () => {
        if( currentPage > 0 ) {
            const prevPage = currentPage - 1;
            const start = prevPage * pageSize;
            const end = pageSize * currentPage;
            setReviewsPagged(reviews.slice(start, end));
            setCurrentPage(prevPage);
        }
    }
    
    const onClickForwardPage = cP => {
        if( currentPage + cP < totalPages ) {
            const start = (currentPage + cP) * pageSize;
            const nextPage = currentPage + cP + 1;
            const end = pageSize * nextPage;
            setReviewsPagged(reviews.slice(start, end));
            setCurrentPage(currentPage + 1);
        }
    }

    const buildString = (listOfString, strDefault) => {
        if( Array.isArray(listOfString) ) {
            let lString = '';
            listOfString.map((s, index) => {
                lString = `${lString}${ index > 0 ? ', ' : ''}${s.name}`;
            })
            return lString === '' ? strDefault : lString;
        }
        return listOfString === '' ? strDefault : listOfString;
    }

    const renderReview = review => (
        <div className={ styles.review }>
            <div><span className={ styles.p_title }>Evaluador:</span> {review.authorName}</div>
            <div className={ styles.feedback }>
                <span className={ styles.p_title }>{ defaultStringEmpty(FEEDBACK_DESC[review.rate - 1]) }</span>
                { <FeedbackStarts feedback={review.rate} /> }
            </div>
            <div><span className={ styles.p_title }>Fortalezas:</span> { buildString(review.strengths, 'No se agregaron Fortalezas en esta evaluación.') }</div>
            <div><span className={ styles.p_title }>&Aacute;reas de oportunidad:</span> { buildString(review.opportunities, 'No se agregaron Áreas de oportunidad en esta evaluación.') }</div>
        </div>
    );

    const renderDetailSection = () => reviewsPagged.map((review, index) => (
        <div key={index} className={ styles.review_card } onClick={ () => onClickDetail(review) }>
            <div className={ styles.section }>
                <div className={ styles.title }>
                    <div className={ styles.title_bar } />
                    <div className={ styles.title_rewiew }>
                        <h2 className={ styles.p_title }>Evaluaci&oacute;n del {review.period}</h2>
                    </div>
                    <div className={ `${styles.title_bar} ${styles.title_bar_thin_gray}` } />
                    <div className={ styles.stars }>
                        <span className={ styles.p_title }>Estatus: </span>
                        { review.processStatus?.name || getStatusDescription(review.processStatus?.id, catFeedbackStatus) }
                        <div className={ styles.stars }>{ <FeedbackArrows status={review.processStatus?.id} catStatus={catFeedbackStatus} /> }</div>
                    </div>
                </div>
                <div className={ styles.content }>
                    { renderReview(review) }
                </div>
            </div>
            <img className={ styles.arrow_view } src={ arrowView } />
        </div>
    ))

    const renderNotFound = () => (
        <div className={ styles.not_found }>
            <div>
                <p className={ styles.h2 }>Sin evaluaciones recibidas</p>
                <p className={ styles.h3 }>{appContext.talent.firstName} a&uacute;n no cuenta con ninguna evaluación de desempe&ntilde;o.</p>
            </div>
            <img src={ ReviewsNotFound } />
        </div>
    )

    const renderFeedbacks = () => {
        const hasReviews = reviews.length > 0;
        return hasReviews ? (
            <div className={ styles.sections }>
                { renderDetailSection() }
                <Pagination
                    totalElements={ reviews.length }
                    currentPage={ currentPage }
                    totalPages={ totalPages }
                    onClickBackPage={ onClickBackPage }
                    onClickForwardPage={ onClickForwardPage }
                />
            </div>
        ) : renderNotFound();
    }

    return <div> { renderFeedbacks() } </div>
}
