import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './textarea.module.scss';
import { borderError } from '../../core/helper';

export const TextArea = ({
    cssCounter,
    name,
	label,
    sublabel,
    maxLength = 100,
    placeholder = '',
    value,
	onChange,
    error,
    focus
}) => {

    const [counter, setCounter] = useState(`${value.length}/${maxLength}`);

    const handleOnChange = ({ target }) => {
        setCounter(`${target.value.length}/${maxLength}`);
        onChange(name, target.value);
    }

    const ref = useRef();

    useEffect(() => {
        if( focus ) {
            setTimeout(() => {
                ref.current.focus();
            }, 0)
        }
    }, [focus]);

    return (
        <div className={ styles.container }>
            <p className={ styles.label }>{ label }</p>
            <p className={ styles.sublabel }>{ sublabel }</p>
            <div className={ styles.textarea } style={ borderError(value.length >= maxLength || (error && value.length === 0)) }>
                <div className={ styles.textarea_scroll }>
                    <textarea ref={ ref }
                        name={ name }
                        placeholder={ placeholder }
                        value={ value }
                        cols="30" rows="10" 
                        maxLength={ maxLength }
                        onChange={ handleOnChange }>
                    </textarea>
                </div>
            </div>
            <div className={ styles.under_text }>
                <div>
                    { 
                        value.length === maxLength && 
                        (<p className={ styles.error_message }>Has alcanzado el límite de caracteres permitido.</p>) 
                    }
                    { error && value.length === 0 && (<p className={ styles.error_message }>{ error }</p>) }
                </div>
                <div className={ cssCounter }>
                    <label className={ value.length >= maxLength ? styles.error_message : '' }>{ counter }</label>
                </div>
            </div>
        </div>
    )
}

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    focus: PropTypes.bool,
};