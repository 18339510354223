import { useContext, useEffect } from 'react';
import styles from '../../talentInfo.module.scss';
import stylesB from '../../../../components/barevaluation/barevaluation.module.scss';
import { AppContext } from '../../TalentMain';
import { userData } from '../../../../core/helper';
import { BarEvaluation } from '../../../../components/barevaluation/BarEvaluation';
import { Loading } from '../../../../components/loading/Loading';
import backArrowIcon from '../../../../../assets/icons/arrow_forw_ena.svg';
import { Skills } from '../evaluation/TalentSkills';
import {
    getCatalog,
    getCheckUpByFeedback,
    getCheckUpFollowUp,
    getCheckUpQuestions,
    getComments,
    getImprovements,
    getInternalNotes,
    getUser,
    getWorkEnv
} from '../../../../api/talentInfoClient';

export const getCalificationStyle = (size, calificationColor) => {
    return {
        fontSize: size + 'px',
        fontWeight: '800',
        color: calificationColor
    }
}

export const TalentCheckup = () => {

    const appContext = useContext(AppContext);

    const getCalification = items => {
        const calification = {
            value: 0,
            color: '',
            description: ''
        };
        if( items ) {
            const catLevelSatisfactionId = 1000570000;
            const sum = items.reduce((n, { satisfactionLevelId }) => n + (satisfactionLevelId - catLevelSatisfactionId), 0);
            const average = sum / items.length;
            calification.value = average.toFixed(1);
            const levelDescription = appContext.catLevelSatisfaction.find( c => c.id === (Math.floor(average) + catLevelSatisfactionId) );
            calification.color = levelDescription.description;
            calification.description = levelDescription.optionName;
        }
        return calification;
    }

    const finishLoading = checkup => {
        appContext.setCheckup(checkup);
        appContext.setModalFlag(false);
    }

    const loadCreator = checkup => {
        // console.log('Loading creator')
        if( checkup.createdBy ) {
            getUser(checkup.createdBy).then(({status, data}) => {
                if( status === 200 && data.code === 202 && data.result ) {
                    const { firstName, lastName } = data.result;
                    const newCheckup = {
                        ...checkup,
                        creator: `${firstName} ${lastName}`
                    }
                    finishLoading(newCheckup);
                } else {
                    finishLoading(checkup);
                }
            }).catch(error => {
                console.log('Error loading creator', error)
                finishLoading(checkup);
            })
        } else {
            finishLoading(checkup);
        }
    }

    const getCommentsInit = (comments, typeId, items = []) => {
        comments.forEach( c => {
            const itemFinded = items.find( i => i[typeId] === c.id )
            c.value = itemFinded ? {
                id: itemFinded.satisfactionLevelId,
                comment: itemFinded.comments
            } : { id: 1000570003, comment: '' };
        })
        return comments;
    }

    const loadFollowUp = (checkup, items) => {
        getCheckUpFollowUp().then(({status, data}) => {
            if( status === 200 && data && data.code === 202 && data.result && Array.isArray(data.result) ) {
                checkup.followUps = getCommentsInit(data.result.filter(c => c.isActive), 'talentCheckUpFollowItemTypeId', items);
                loadCreator(checkup);
            } else {
                finishLoading(checkup);
            }
        }).catch( error => {
            console.log('Error loading follo ups', error);
            finishLoading(checkup);
        });
    }

    const getNotesInit = (internalNotes, catInternalNotes) => {
        const notes = []
        catInternalNotes.forEach(c => {
            const internalNote = internalNotes ? internalNotes.find(i => i.noteTypeId === c.id) : null
            notes.push({
                noteTypeId: internalNote ? internalNote.noteTypeId : c.id,
                noteTypeTitle: c.optionName,
                comments: internalNote ? internalNote.comments : '',
                isNew: internalNote ? false : true
            })
        })
        return notes;
    }

    const loadInternalNotes = (checkup, comments, catInternalNotes) => {
        // console.log('Loading internal notes')
        if( checkup && checkup.id ) {
            getInternalNotes(userData().userId, appContext.talent.id, appContext.review.id, checkup.id).then(({status, data}) => {
                if( status === 200 && data.code === 202 && Array.isArray(data.result) && data.result.length > 0 ) {
                    checkup.internalNotes = getNotesInit(data.result, catInternalNotes);
                } else {
                    checkup.internalNotes = getNotesInit(null, catInternalNotes);
                }
                loadFollowUp(checkup, comments);
            }).catch(error => {
                console.log('Error loading internal notes', error);
                checkup.internalNotes = getNotesInit(null, catInternalNotes);
                finishLoading(checkup);
            })
        } else {
            checkup.internalNotes = getNotesInit(null, catInternalNotes);
            loadFollowUp(checkup, comments);
        }
    }

    const buildImprovement = (skill, comment) => {
        return {
            feedbackId: comment ? comment.feedbackId : appContext.review.id,
            skillTagId: skill.tagId,
            comments: comment ? comment.comments: '',
            createdBy: comment ? comment.createdBy : userData().userId,
            name: skill.name,
            categoryId: skill.categoryId
        }
    }

    const getImprovementInit = (comments) =>  {
        const improvements = [];
        appContext.review.opportunities.forEach(o => {
            const comment = comments ? comments.find(c => c.skillTagId === o.tagId) : null;
            improvements.push(buildImprovement(o, comment));
        })
        return improvements;
    }

    const loadImprovementActions = (checkup, followItems, catalogs) => {
        getImprovements(userData().userId, appContext.talent.id, appContext.review.id).then(({status, data}) => {
            if( status === 200 && data.code === 202 && Array.isArray(data.result) && data.result.length > 0 ) {
                checkup.improvements = getImprovementInit(data.result);
            } else {
                checkup.improvements = getImprovementInit();
            }
            loadInternalNotes(checkup, followItems, catalogs[3]);
        }).catch(error => {
            console.log(error);
            checkup.improvements = getImprovementInit();
            finishLoading(checkup);
        })
    }

    const loadFeedbackComments = (checkup, followItems, catalogs) => {
        checkup.feedbackComments = [];
        getComments(userData().userId, appContext.talent.id, appContext.review.id).then(({status, data}) => {
            if( status === 200 && data.code === 202 && Array.isArray(data.result) && data.result.length > 0 ) {
                checkup.feedbackComments = data.result;
            } else {
                checkup.feedbackComments = [
                    { typeId: 1, comments: '' },
                    { typeId: 2, comments: '' }
                ]
            }
            loadImprovementActions(checkup, followItems, catalogs);
        }).catch(error => {
            console.log(error);
            finishLoading(checkup);
        })
    }

    const loadWorkEnviroiment = (checkup, followItems, catalogs, preCheckupId) => {
        // console.log('Loading work enviroiment')
        const newCheckup = { ...checkup }
        getWorkEnv(userData().userId, appContext.talent.id, preCheckupId || checkup.id).then(({status, data}) => {
            // console.log('loading work env', status, data.code)
            if(status === 200 && data.code === 202) {
                newCheckup.workEnviroimentId = data.result.talentId;
                newCheckup.clasification = catalogs[0].find( c => c.id === data.result.classificationId );
                newCheckup.existRisk = catalogs[1].find( c => c.id === data.result.exitRisk );
            }
            loadFeedbackComments(newCheckup, followItems, catalogs);
        }).catch(error => {
            loadFeedbackComments(newCheckup, followItems, catalogs);
            console.log('Error loading work env', error);
        })
    }

    const loadCheckup = (checkup, comments, catalogs) => {
        // getCheckUpByTalent(userId, appContext.talent.id).then(response => {
        getCheckUpByFeedback(userData().userId, appContext.talent.id, appContext.review.id).then(({status, data}) => {
            const { result } = data;
            let checkUpTmp = {
                ...checkup,
                comments: getCommentsInit(comments.filter(c => c.isActive))
            };
            let followItems = [];
            if( status === 200 && data.code === 202 && result && result.checkUp) {
                checkUpTmp = {
                    ...checkup,
                    ...result.checkUp,
                    calification: getCalification(result.items),
                    comments: getCommentsInit(comments.filter(c => c.isActive), 'talentCheckUpItemTypeId', result.items)
                }
                followItems = result.followItems;
                loadWorkEnviroiment(checkUpTmp, followItems, catalogs);
            } else if( status === 202 && data.code === 404 && !result) {
                getCheckUpByFeedback(userData().userId, appContext.talent.id, 0).then(({status, data}) => {
                    if( status === 200 && data.code === 202 && data.result && data.result.checkUp) {
                        checkUpTmp.calification = getCalification(data.result.items);
                        checkUpTmp.comments = getCommentsInit(comments.filter(c => c.isActive), 'talentCheckUpItemTypeId', data.result.items);
                        loadWorkEnviroiment(checkUpTmp, followItems, catalogs, data.result.checkUp.id);
                    } else {
                        loadFeedbackComments(checkUpTmp, followItems, catalogs);
                    }
                }).catch(error => {
                    console.log('Error loading checkup', error);
                    finishLoading(checkUpTmp);
                })
            } else {
                finishLoading(checkUpTmp);
            }
        }).catch(error => {
            console.log(error);
            finishLoading(checkup);
        })
    }

    const loadQuestions = catalogs => {
        const checkup = {
            clasification: catalogs[0].find( c => c.id === 1000580001 ),
            existRisk: catalogs[1].find( c => c.id === 1000560001 )
        }
        getCheckUpQuestions().then(({status, data}) => {
            if( status === 200 && data && data.code === 202 && data.result && Array.isArray(data.result) ) {
                loadCheckup(checkup, data.result, catalogs);
            } else {
                finishLoading(checkup);
            }
        }).catch( error => {
            console.log('Error loading questions cat', error);
            finishLoading(checkup);
        })
    }

    useEffect(() => {
        appContext.setModalContent( <Loading /> );
        appContext.setModalFlag(true);
        const sorter = '&sort=id&direction=ASC';
        const requestCatalogs = [
            getCatalog(1000030058, 0, 200, sorter),
            getCatalog(1000030056, 0, 200, sorter),
            getCatalog(1000030057, 0, 200, sorter),
            getCatalog(1000020059, 0, 200, sorter)
        ]
        Promise.all(requestCatalogs).then(responses => {
            const catalogs = [];
            responses.forEach(({status, data}, index) => {
                if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
                    let catTmp = [];
                    data.result.content.map( l => {
                        const type = { id: l.catalogItemId, optionName: l.valueItem, description: l.description }
                        catTmp.push(type);
                    })
                    catalogs.push(catTmp);
                }
            })
            if(catalogs.length === requestCatalogs.length) {
                loadQuestions(catalogs);
            }
        })
    }, [])

    const renderBarEvaluation = () => appContext.checkup.comments.map((c, index) => (
        <div key={ index }>
            <BarEvaluation
                className={ stylesB.line }
                title={ c.title }
                value={ c.value }
                options={ appContext.catLevelSatisfaction }
                width={ 45 }
                modeRead
            />
        </div>
    ))

    const renderClimate = () => (
        <div className={ styles.section }>
            <div className={ styles.section_review }>
                <div className={ styles.title }>
                    <div className={ styles.title_bar } />
                    <div className={ styles.title_rewiew }>
                        <h2 className={ styles.p_title }>Clima organizacional:</h2>
                    </div>
                    <span style={ getCalificationStyle(20, appContext.checkup?.calification?.color) }>
                        {`${appContext.checkup?.calification?.value} - ${appContext.checkup?.calification?.description}`}
                    </span>
                </div>
                <div className={ styles.title }>
                    <span>Te mostramos c&oacute;mo { appContext.talent.firstName } evalu&oacute; su experiencia en diferentes aspectos de su clima organizacional actual.</span>
                </div>
                { appContext.checkup && appContext.checkup.comments &&
                    <div className={ `${styles.section} ${styles.section_gap12px}` }>
                        <span className={styles.p_title}>Opini&oacute;n de { appContext.talent.firstName }:</span>
                        { renderBarEvaluation() }
                    </div>
                }
                <div className={ styles.link } onClick={ () => appContext.setTab(7) }>
                    <span className={ `${styles.p_title} ${styles.p_title_blue}` }>Detalle sobre Clima organizacional</span>
                    <img className={ styles.arrow } src={backArrowIcon} alt="" />
                </div>
            </div>
        </div>
    )

    const renderSkills = (title, subtitle, skills, commentLeader, commentSelf) => (
        <div className={ styles.section }>
            <div className={ styles.title }>
                <div className={ styles.title_bar } />
                <div className={ styles.title_rewiew }>
                    <h2 className={ styles.p_title }>{title}</h2>
                </div>
            </div>
            <div className={ styles.content }>
                <Skills skills={ skills } />
            </div>
            <div className={ styles.section_review }>
                <div className={ styles.comments }>
                    <span className={ styles.p_title }>Comentarios del l&iacute;der</span>
                    <span>{ commentLeader ? commentLeader : `No se agregaron comentarios sobre ${subtitle}.` }</span>
                </div>
                <div className={ `${styles.comments} ${styles.comments_grayblue}` }>
                    <span className={ styles.p_title }>Comentarios de {appContext.talent.firstName} {appContext.talent.lastName}</span>
                    <span>{ commentSelf ? commentSelf : `No se agregaron comentarios sobre ${subtitle}.` }</span>
                </div>
            </div>
        </div>
    )

    const renderItemsResume = () => appContext.checkup.improvements.map((i, index) => (
        <div key={index} className={ `${styles.table_row} ${styles.row_backgray}` }>
            <div className={ styles.column_main }>
                <span className={ styles.p_title }>{ i.name }</span>
            </div>
            <div className={ `${styles.column_detail} ${styles.comment}` }>{ i.comments ? i.comments : `No se agregaron comentarios sobre ${i.name}.` }</div>
        </div>
    ))

    const renderTable = () => (
        <div className={ styles.table }>
            <div className={ `${styles.table_row} ${styles.table_header}` }>
                <div className={ styles.column_short }>Oportunidad</div>
                <div className={ styles.column_detail }>Acciones de mejora</div>
            </div>
            <div className={ `${styles.table_detail} ${styles.gap16}` }>
                { appContext.checkup && appContext.checkup.improvements && renderItemsResume() }
            </div>
        </div>
    )

    const renderImprovements = () => (
        <div className={ styles.section }>
            <div className={ styles.section_review }>
                <div className={ styles.title }>
                    <div className={ styles.title_bar } />
                    <div className={ styles.title_rewiew }>
                        <h2 className={ styles.p_title }>Acciones de mejora</h2>
                    </div>
                </div>
                <div className={ styles.title }>
                    <span>Estos son los detalles de las acciones de mejora que se definieron para {appContext.talent.firstName}.</span>
                </div>
                { renderTable() }
            </div>
        </div>
    )

    return (
        <div className={ styles.sections }>
            <div className={ styles.section_review }>
                <div className={ styles.section_main }>
                    <span className={ styles.title_24px }>Reporte de evaluaci&oacute;n del {appContext.review.period}</span>
                </div>
            </div>
            { renderClimate() }
            { appContext.review.strengths && appContext.review.strengths.length > 0 && renderSkills('Fortalezas', 'Fortalezas', appContext.review.strengths, appContext.review.strengthsComment, appContext.checkup?.feedbackComments?.find(c => c.typeId === 1).comments) }
            { appContext.review.opportunities && appContext.review.opportunities.length > 0 && renderSkills('Áreas de oportunidad', 'áreas de oportunidad', appContext.review.opportunities, appContext.review.opportunitiesComment, appContext.checkup?.feedbackComments?.find(c => c.typeId === 2).comments) }
            { appContext.checkup?.improvements && appContext.checkup.improvements?.length > 0 && renderImprovements() }
        </div>
    )
}
