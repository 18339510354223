import { useContext } from 'react';
import styles from '../../talentInfo.module.scss';
import { AppContext } from '../../TalentMain';
import { BarEvaluation } from '../../../../components/barevaluation/BarEvaluation';
import { getCalificationStyle } from './TalentCheckup';

export const TalentClimate = () => {

    const appContext = useContext(AppContext);

    const renderTable = () => (
        <div className={ styles.section }>
            <div className={ styles.section_review }>
                <div className={ styles.table }>
                    <div className={ `${styles.table_row} ${styles.table_header}` }>
                        <div className={ styles.column_main }>Nivel de satisfacción</div>
                        <div className={ styles.column_detail }>Comentarios</div>
                    </div>
                    <div className={ `${styles.table_detail} ${styles.gap8}` }>
                        {
                            appContext.checkup.comments && appContext.checkup.comments.map((c, index) => (
                                <div key={index} className={ `${styles.table_row} ${styles.row_backgray}` }>
                                    <div className={ `${styles.column_main} ${styles.bar} ${styles.gap16}` }>
                                        <span className={ styles.label }>{ c.title }</span>
                                        <BarEvaluation
                                            classMain={ `${styles.bar} ${styles.gap4}` }
                                            value={ c.value }
                                            options={ appContext.catLevelSatisfaction }
                                            modeRead
                                            width={ 70 }
                                        />
                                    </div>
                                    <div className={ `${styles.column_detail} ${styles.comment}` }>{ c.value.comment }</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div className={ styles.sections }>
            <div className={ styles.section_review }>
                <div className={ styles.title }>
                    <span className={ styles.title_24px }>Clima organizacional: </span>
                    <span style={ getCalificationStyle(24, appContext.checkup?.calification?.color) }>
                        {`${appContext.checkup?.calification?.value} - ${appContext.checkup?.calification?.description}`}
                    </span>
                </div>
                <div className={ styles.title }>
                    <span>Te mostramos c&oacute;mo { appContext.talent.firstName } evalu&oacute; su experiencia en diferentes aspectos de su clima organizacional actual.</span>
                </div>
            </div>
            { renderTable() }
        </div>
    )
}
