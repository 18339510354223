import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { decrypt } from '../core/helper';
import { doLogin } from "../api/talentInfoClient";

export const ValidateParam = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const login = userInfo => {
      const requestLogin = {
        platformId: 1005000005,
        username: userInfo.authUsername,
        password: userInfo.authPassword
      }

      doLogin(requestLogin).then(({ status, data }) => {
        if( status === 200 && data.code === 100 ) {
          delete userInfo.authUsername;
          delete userInfo.authPassword;
          const token = data.result.authInfo.accessToken;
          localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, 'token': token }));
          navigate('/my-services');
        } else {
          navigate('/invalid-data');
        }
      }).catch(error => {
        console.log(error);
        navigate('/invalid-data');
      })
    }

    useEffect(() => {
      const params = searchParams.get("params");
      if( !params ) {
        navigate('/invalid-data');
      } else {
        const userInfo = decrypt(params);
        if(userInfo) {
          login(userInfo);
        } else {
          navigate('/invalid-data');
        }
      }
    }, [])
}
