import { appApi } from './apiInstance';

const apiUrl = process.env.REACT_APP_GATEWAY_URL;

const mainContext = 'user';
const authModule = 'auth';
const catalogModule = 'catalog';
const talentsModule = 'talents';
const talentModule = 'talent';
const skillModule = 'skills';
const feedbackModule = 'feedback';
const checkupModule = 'check-up';
const feedbackComments = 'comments';
const improvementActions = 'improvement-actions';

export const doLogin = request => appApi(apiUrl).post(`${mainContext}/${authModule}/login`,request);
export const getTalents = (userId, statusId, filter, page=0, size=10) => {
    const filterParam = filter ? `&filter=${filter}` : '';
    return appApi(apiUrl).get(`${mainContext}/${userId}/${talentsModule}/pageable?statusId=${statusId}&page=${page}&size=${size}&sort=firstName&direction=ASC${filterParam}`)
};
export const getTalent = talentId => appApi(apiUrl).get(`${talentModule}/${talentId}`);
export const getFeedbacks = (userId, talentId) => appApi(apiUrl).get(`${mainContext}/${userId}/${talentModule}/${talentId}/${feedbackModule}`);
export const getFeedbackStatus = () => appApi(apiUrl).get(`${catalogModule}/status-process`);
export const saveFeedback = (userId, talentId, feedback) => appApi(apiUrl).post(`${mainContext}/${userId}/${talentModule}/${talentId}/${feedbackModule}`, feedback);
export const getCheckUpQuestions = () => appApi(apiUrl).get(`${catalogModule}/talent-check-up-item-types`);
export const getCheckUpFollowUp = () => appApi(apiUrl).get(`${catalogModule}/talent-check-up-follow-item-types`);
export const getCheckUpByTalent = (userId, talentId) => appApi(apiUrl).get(`${mainContext}/${userId}/${talentModule}/${talentId}/${checkupModule}`);
export const getCheckUpByFeedback = (userId, talentId, feedbackId) => appApi(apiUrl).get(`${mainContext}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}`);
export const getComments = (userId, talentId, feedbackId) => appApi(apiUrl).get(`${mainContext}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${feedbackComments}`);
export const getWorkEnv = (userId, talentId, checkupId) => appApi(apiUrl).get(`${mainContext}/${userId}/${talentModule}/${talentId}/${checkupModule}/${checkupId}/work-environment`);
export const getImprovements = (userId, talentId, feedbackId) => appApi(apiUrl).get(`${mainContext}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${improvementActions}`);
export const getInternalNotes = (userId, talentId, feedbackId, checkupId) => appApi(apiUrl).get(`${mainContext}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}/${checkupId}/internal-notes`);
export const getUser = userId => appApi(apiUrl).get(`${mainContext}/${userId}`);
export const downloadCv = talentId => {
    return appApi(apiUrl).get(`${talentModule}/${talentId}/downloadcv`, {
        responseType: 'blob'
    })
}
export const getSkills = talentId => appApi(apiUrl).get(`${talentModule}/${talentId}/${skillModule}`);
export const getSkillsCatalog = (talentId, page ,size) => appApi(apiUrl).get(`${catalogModule}/${skillModule}/${feedbackModule}/${talentModule}/${talentId}?page=${page}&size=${size}&sort=name&direction=ASC`);
export const getCatalog = (catalogId, page ,size, sort) => appApi(apiUrl).get(`${catalogModule}/${catalogId}/catalogItem?page=${page}&size=${size}${sort}`);