import styles from '../../talentInfo.module.scss';

export const Skills = ({
    categories, 
    skills
}) => {

    const catTitles = [
        { categoryId: 0, name: 'Sin clasificar' },
        { categoryId: 1001100001, name: 'Competencias Técnicas' },
        { categoryId: 1001100002, name: 'Competencias Personales' },
        { categoryId: 1001100003, name: 'Hábitos de trabajo' },
        { categoryId: 1001100004, name: 'Software' }
    ]

    const renderCategoryCap = categoryId => (
        <div className={ styles.inline }>
            {
                skills?.filter(s => s.categoryId === categoryId).map( (s, index) => (
                    <div key={ index } className={ styles.e_item }>{ s.name }</div>
                ))
            }
        </div>
    )

    const renderGrouped = () => categories.map( ( categoryId, index ) => {
        return skills.filter(s => s.categoryId === categoryId).length > 0 && (
            <div key={ index } className={ styles.e_title }>
                {catTitles.find( c => c.categoryId === categoryId).name}
                { renderCategoryCap(categoryId) }
            </div>
        )
    })

    const renderNoGrouped = () => (
        <div className={ styles.inline }>
            {
                skills?.map( (s, index) => (
                    <div key={ index } className={ styles.e_item }>{ s.name }</div>
                ))
            }
        </div>
    )

    return (
        <div className={ styles.pharagrap }>
            { categories ? renderGrouped() : renderNoGrouped() }
        </div>
    )
}
