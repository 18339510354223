import { useEffect, useState } from 'react';
import styles from './barevaluation.module.scss';

export const BarEvaluation = ({
    className,
    title,
    value,
    options,
    width
}) => {

    const [label, setLabel] = useState();
    const [currentSelected, setCurrentSelected] = useState()
    
    useEffect(() => {
        const optionSelected = options.find( l => l.id === value.id);
        if( optionSelected ) {
            setLabel(title ? title : optionSelected.optionName);
        }
        setCurrentSelected(options.findIndex( o => o.id === value.id ) + 1);
    }, [value, options])

    const simpleSelected = () => {
        const styleSelected = {}
        const perc = 100 / options.length;
        const optionSelected = options.find(o => o.id === value.id);
        if( optionSelected ) {
            styleSelected.backgroundColor = optionSelected.description;
            styleSelected.width = `${perc * currentSelected}%`
        }
        return styleSelected;
    }

    const renderSimpleBar = () => (
        <div className={ styles.bar_simple }>
            <div className={ styles.progress } style={simpleSelected()}></div>
        </div>
    )

    return (
        <div className={ className }>
            <span>{ label }</span>
            <div className={ styles.bar_line } style={ width ? { width: `${width}%` } : {} }>
                { renderSimpleBar() }
                <span>{ currentSelected }/{ options.length }</span>
            </div>
        </div>
    )
}
