import { decryptString, encryptString } from "./crypto";
import { yearsOrder } from "./hardcode";

export const userData = () => {
    const datals = localStorage.getItem('userInfo');
    return JSON.parse(datals);
};

export const decrypt = params => {
    const paramsSanitized = params.replaceAll(' ', '+').replaceAll(/["]/g, '');

    // ===== Data Template ==============
    // {"userId":1,"email":"axel.mendoza@mystratis.com","roleId":1000000009,"name":"Axel Mendoza","clientId":1000000009,"clientName":"Pruebas Marlon QA","authUsername":"usernameCognito","authPassword":"userPasswordCognito","talentId":376,"feedbackId":479,"checkupId":4}
    // ===== Token for user 9 ==============
    // Token normal
    // const workEncrypt = encryptString('{"userId":9,"email":"oziel.naranjo@mystratis.com","roleId":1000000003,"name":"Oziel Naranjo","clientId":14,"authUsername":"usernameCognito","authPassword":"userPasswordCognito"}');
    // console.log(workEncrypt); // yiJ39FxntYeVuUBtKZex4jXLgIjkbRYjd8ohAxHjpahvmVrzYj7447NBgwj/xtQ8gGManaXxhvNxjC/9esR/KoA3UjWXryYDHrgF1HCptxvbU+zJMfxCHKwZAfOVW1jUJLuD2Y+OCHFDYkepQlSaR3ozIpUmOokOsSXiu2ck7oCV4Au4+YK4aQZ1D4TgV3pFjgD5mLJ0JhrQcABM/pnu88enSGRjLOmN70eLvfj5DJSb3xgNhU8pCBeRsg0rMTr/
    // Token to see checkup report
    // const workEncrypt = encryptString('{"userId":1,"email":"axel.mendoza@mystratis.com","roleId":1000000009,"name":"Axel Mendoza","clientId":1000000009,"clientName":"Pruebas Marlon QA","talentId":376,"feedbackId":479,"checkupId":4}');
    // const workEncrypt = encryptString('{"userId":9,"email":"marlon.canales@mystratis.com","roleId":1000000010,"name":"Marlon Canales","clientId":1000000009,"clientName":"Pruebas Marlon QA","talentId":49,"feedbackId":712,"checkupId":171,"expirationDate":"2024-12-10T21:18:50.211Z","authUsername":"estaffing.aspirante.general@mystratis.com","authPassword":"1c\u003e4X1VZj$b!h"}')
    // console.log('workEncrypt', workEncrypt); // yiJ39FxntYeVuUBtKZex4m1VDL5EJqRWRQ+H6hxLfSVfM2flLBqcmtKV8ByUQeqSRgxzfNGEvYXic8cKkmc0SR0iUSkXYOVpXgK3sj7bdzSWChvCb/Q51aoGtbDPnM43ApqsPZsLjjet9ZlwJindGCn1769oSDG0655NGoB08fbRiHtoW+00cWCiIoP7ETYoZtI/qYxnjMIjPg7z1gAkqosopPDPt404MEFh6fGsVl/ZhMYESCHGnwrDKDH9+5nunI1yaWdf1j3mIYRQHYUxbhBZtOOIQGAYSgN8huaQHgA7rjZP+5gkvAaVUS+1lJx3NyladtnSg972vHRbhtOZFS1nsZk+lxbc/UAoOkPdurahM3RYS9ejoWtU6/7Zo/V2f1sZTPT+o4F/cEh5yjRv4IghDemZr4KAd8i3s+DJVdm7GDi2dYuxe0xb6zqKE4df

    const decrypted = decryptString(paramsSanitized);
    //console.log(decrypted);
    try {
        return JSON.parse(decrypted);
    } catch (error) {
        return null;
    }
}

export const defaultStringEmpty = value => {
    return value && value !== '' ? value : 'Sin especificar';
}

export const unsearializaDateString = (dateStr) => {
    const dateArray = dateStr.split('-');
    const year = dateArray[0];
    const month = Number(dateArray[1]);
    const day = dateArray[2];
    return {
        'day': day,
        monthId: month,
        yearDesc: year
    }
}

export const serializeSelectValue = ({ id, optionName }) => ({
    id: id,
    description: optionName
})

export const sorterDate = (yearId1, monthId1, yearId2, monthId2, order) => {
    let result = 0;
    const yearPos = order === yearsOrder.desc ? yearId1 > yearId2 : yearId1 < yearId2;
    const yearMNeg = order === yearsOrder.desc ? yearId1 < yearId2 : yearId1 > yearId2;
    if (yearPos) {
        result = 1
    } else if (yearMNeg) {
        result = -1
    }
    if( result === 0 ) {
        if (monthId1 < monthId2) {
            result = 1
        } else if (monthId1 > monthId2) {
            result = -1
        }
    }
    // console.log('sorter date', yearId1, monthId1, yearId2, monthId2, result);
    return result;
}
export const sorterAlphabetic = (a, b) => {
    const textA = a.toUpperCase();
    const textB = b.toUpperCase();
    const result = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    return result;
}

export const borderError = error => error ? { borderColor: 'var(--color-error)' } : {}

export const stringCutter = (value, maxLength) => value.length > maxLength ? `${value.substring(0, maxLength)}...` : value