import { useContext, useEffect, useState } from 'react';
import styles from '../../talentInfo.module.scss';
import { AppContext } from '../../TalentMain';
import { FEEDBACK_DESC } from '../../../../core/hardcode';
import { Skills } from './TalentSkills';
import { getStatusDescription } from './TalentEvaluations';
import { FeedbackArrows } from './FeedbackArrows';
import { FeedbackStarts } from './FeedbackStarts';

export const TalentEvaluation = ({
    catFeedbackStatus
}) => {

    const appContext = useContext(AppContext);

    const [review, setReview] = useState(appContext.review);

    useEffect(() => {
        setReview(appContext.review);
    }, [appContext.review])

    const renderReview = review => (
        <div className={ styles.review }>
            <div className={ styles.feedback }>
                { <FeedbackStarts feedback={review.rate} /> }
            </div>
        </div>
    );

    const renderDetailSection = (title, child) => (
        <div className={ styles.section }>
            <div className={ styles.title }>
                <h2 className={ styles.p_title }>{ title }</h2>
            </div>
            <div className={ styles.content }>
                { child }
            </div>
        </div>
    )

    const renderSkillsDetail = (skills, title, comments) => (
        <div>
            { skills.length > 0 ? <Skills categories={ [0, 1001100001, 1001100002, 1001100004] } skills={ skills } /> : <p className={ styles.padding_y_16 }>{`No se agregaron ${title} en esta evaluación.`}</p> }
            { skills.length > 0 && <div className={ styles.pharagrap }>
                <div className={ styles.e_title }>
                    Comentarios sobre {title}
                </div>
                <p>{ !comments || comments === '' ? `No se agregaron comentarios sobre ${title}` : comments}</p>
            </div>}
        </div>
    )

    const renderSkillsSection = () => {

        return (
            <div className={ styles.sections }>
                { renderDetailSection( 'Fortalezas', renderSkillsDetail(review.strengths, 'Fortalezas', review.strengthsComment) ) }
                { renderDetailSection( 'Áreas de Oportunidad', renderSkillsDetail(review.opportunities, 'Áreas de Oportunidad', review.opportunitiesComment) ) }
            </div>
        )
    }

    const renderButtons = () =>  (
        <div className={ styles.section_main }>
            <div className={ `${styles.section_main} ${styles.button}` } onClick={ () => appContext.setTab(6) }>
                <span>Ver reporte de evaluaci&oacute;n</span>
            </div>
        </div>
    )

    return (
        <div className={ styles.sections }>
            <div className={ styles.section_review }>
                <div className={ styles.section_main }>
                    <span className={ styles.title_24px }>Evaluaci&oacute;n del {review.period}</span>
                    { appContext.review?.processStatus?.id === 1000530003 && renderButtons() }
                </div>
                <div className={ styles.title }>
                    <div className={ styles.stars }>
                        <span className={ styles.p_title }>Estatus: </span>
                        { review.processStatus?.name || getStatusDescription(review.processStatus?.id, catFeedbackStatus) }
                        <div className={ styles.stars }>{ <FeedbackArrows status={review.processStatus?.id} catStatus={catFeedbackStatus} /> }</div>
                    </div>
                </div>
                <div className={ styles.title }>
                    <span className={ styles.p_title }>Evaluador:</span> {review.authorName}
                </div>
                {renderDetailSection('Calificación:', (
                    <div className={ styles.title }>
                        { FEEDBACK_DESC[review.rate - 1] }
                        { renderReview(review) }
                    </div>
                ))}
            </div>
            { renderSkillsSection() }
        </div>
    )
}
