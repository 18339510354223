import styles from '../../talentInfo.module.scss';
import feedbackStart from '../../../../../assets/icons/feedback_start.svg';

export const FeedbackStarts = ({
    feedback
}) => {

    return (
        <div className={ styles.stars }>
            <img width='19px' height='18px' className={ feedback >= 1 ? styles.selected : '' } src={ feedbackStart } alt="" />
            <img width='19px' height='18px' className={ feedback >= 2 ? styles.selected : '' } src={ feedbackStart } alt="" />
            <img width='19px' height='18px' className={ feedback >= 3 ? styles.selected : '' } src={ feedbackStart } alt="" />
            <img width='19px' height='18px' className={ feedback >= 4 ? styles.selected : '' } src={ feedbackStart } alt="" />
            <img width='19px' height='18px' className={ feedback >= 5 ? styles.selected : '' } src={ feedbackStart } alt="" />
        </div>
    )
}
