import styles from '../../talentInfo.module.scss';
import arrowCircle from '../../../../../assets/icons/arrow_circle.svg';

export const FeedbackArrows = ({
    status,
    catStatus
}) => {

    const getStatusColor = (status, statusToEvaluate, statusComplete) => {
        return status === statusComplete ? styles.circle_completed : (status >= statusToEvaluate ? styles.circle_selected : '')
    }

    const renderArrows = () => catStatus && catStatus.map((s, index) => (
        <div key={index} className={styles.arrow_line}>
            <div className={ `${getStatusColor(status, s.id, catStatus[catStatus.length-1].id)}` }><img src={ arrowCircle } alt="" /></div>
            { index < catStatus.length -1 && <div className={ `${styles.circle_mini} ${getStatusColor(status, catStatus[index+1].id, catStatus[catStatus.length-1].id)}` } /> }
            { index < catStatus.length -1 && <div className={ `${styles.circle_mini} ${getStatusColor(status, catStatus[index+1].id, catStatus[catStatus.length-1].id)}` } /> }
        </div>
    ))

    return (
        <div className={ styles.arrow }>
            { renderArrows() }
        </div>
    )
}
